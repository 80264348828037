volume-pricing {
  display: block;
  margin-top: 2rem;
}

volume-pricing li:nth-child(odd) {
  background: rgba(var(--color-foreground), 0.04);
}

volume-pricing ul {
  margin-top: 1.2rem;
}

volume-pricing li {
  font-size: 1.4rem;
  letter-spacing: 0.06rem;
  padding: 0.6rem 0.8rem;
  display: flex;
  justify-content: space-between;
}

div.volume-pricing-note {
  margin-top: -2.6rem;
}

.product--no-media .volume-pricing-note {
  text-align: center;
}

.product--no-media volume-pricing {
  width: 30rem;
}

.volume-pricing-note span,
volume-pricing .label-show-more {
  font-size: 1.2rem;
  letter-spacing: 0.07rem;
}

volume-pricing show-more-button {
  margin-top: 0.8rem;
}

.product-form__input price-per-item,
.price-per-item__container {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.product-form__input .price-per-item {
  color: rgba(var(--color-foreground));
  font-size: 1.4rem;
  letter-spacing: 0.06rem;
}

.price-per-item dl {
  margin: 0;
}

.price-per-item dd {
  margin-left: 0;
}

.price-per-item__container .variant-item__old-price,
.price__regular .variant-item__old-price {
  color: rgba(var(--color-foreground), 0.75);
  font-size: 1.2rem;
  letter-spacing: 0.07rem;
}
